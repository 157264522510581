/* You can add global styles to this file, and also import other style files */
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(/assets/fonts/Montserrat-Black.ttf);
}
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(/assets/fonts/Montserrat-ExtraBold.ttf);
}

@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/assets/fonts/Montserrat-Bold.ttf);
}

@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/assets/fonts/Montserrat-SemiBold.ttf);
}

@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/assets/fonts/Montserrat-Medium.ttf);
}
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/assets/fonts/Montserrat-Regular.ttf);
}

@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(/assets/fonts/Montserrat-Light.ttf);
}
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url(/assets/fonts/Montserrat-ExtraLight.ttf);
}
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url(/assets/fonts/Montserrat-Thin.ttf);
}

@import "~@ng-select/ng-select/themes/default.theme.css";
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body,
html {
  font-family: "Montserrat", "sans-serif";
  scrollbar-width: none;
  scroll-behavior: smooth;
  letter-spacing: 0.8px;
}

a {
  cursor: pointer;
  text-decoration: none;
}

.bsk-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  vertical-align: middle;
  height: 90vh;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
}

.x-icon {
  height: 1.2em;
  width: 1.2em;
  top: 0.125em;
  position: relative;
  margin-right: 0.8em;
}

.bsk-container button {
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 1em;
  padding: 1em;
  border: none;
  border: 1px solid #ccc;
}

img.logo {
  max-height: 64px;
  max-width: 64px;
  margin-bottom: 6em;
}

.container {
    width: 100%;
    height: 100vh;
    display: flex;
  }

/* left side of the  dashboard */
.left {
  /* margin-top: calc(48px + 0px); */
  /* width: 16vw; */
  /* background: #fafafa; */
  /* height: 100vh; */
  /* display: flex; */
  /* flex-direction: column; */
  /* color: #fff; */
  /* position: fixed; */
  /* overflow-y: scroll; */
  height: 100%;
  /* border-right: 1px solid #d6d6d6; */
  z-index: 1;
  overflow: scroll;
  overflow-x: hidden;
  /* border-top-right-radius: 30px; */
  /* border-bottom-right-radius: 30px; */
  background-color: rgb(250, 250, 250);
}

.right {
  /* width: 80vw; */
  /* margin-top: calc(60px + 0px); */
  /* height: 100vh; */
  overflow: scroll;
  background: #fff;
  /* border: 2px solid red; */
  /* position: fixed; */
  /* right: 0; */
  /* max-width: 1400px; */
  /* flex: 1; */
  padding: 16px;
  right: 0px;
  height: 100%;
  overflow-y: scroll;
  flex: 1;
}

.nav-mobile {
  /* margin: 16px; */
  cursor: pointer;
  pointer-events: bounding-box;
  width: fit-content;
}

.search-icon {
  cursor: pointer;
}
.close-icon {
  display: none;
  position: fixed;
  top: 50px;
  right: 30px;
  cursor: pointer;
}

.close-icon.active {
  display: block;
  z-index: 3;
}

.hamburger {
  display: none;
  width: 33px;
  height: 4px;
  margin-bottom: 5px;
  position: relative;
  background: #cdcdcd;
  border-radius: 3px;
}

.right-container {
  margin: 20px 0px;
}

@media only screen and (max-width: 900px) {
  .left.hide {
    display: none;
    opacity: 0;
    left: -100px;
    transform: translateX(-100px);
    transition: transform 0.4s cubic-bezier(0.3, 0, 0, 1);
  }
  .top.hide {
    opacity: 0;
    pointer-events: none;
  }
  .right {
    width: 100vw;
  }

  .hamburger {
    display: block;
  }

  .top-nav-container {
    display: flex;
    border-bottom: 1px solid #ccc;
  }


  .right {
    margin-top: 16px;
  }
}

@media only screen and (max-width: 600px) {
  .right-container {
    margin: 32px 12px 32px 12px;
  }
}

.navbar.show {
  opacity: 1;
  pointer-events: all;
  transition: opacity 0.1s ease-in-out;
}
.left.show {
  opacity: 1;
  pointer-events: all;
  transition: opacity 0.1s ease-in-out;
  left: 0;
  display: flex;
}
/* table */

.table-block {
  margin-bottom: 64px;
}

.table {
  width: 100%;
  margin-bottom: 1rem;
}

.table th,
.table td {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
  text-align: left;
}

.table thead th {
  vertical-align: bottom;
  /* border-bottom: 2px solid #dee2e6; */
}

.table tbody + tbody {
  border-top: 2px solid #dee2e6;
}

.table-sm th,
.table-sm td {
  padding: 0.3rem;
}

.table-bordered {
  border: 1px solid #dee2e6;
  /* border-radius: 16px; */
}

.table-bordered th,
.table-bordered td {
  border-right: 1px solid #dee2e6;
}

.table-bordered thead th,
.table-bordered thead td {
  /* border-bottom-width: 2px; */
  border: none;
}

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}

.table-hover tbody tr:hover {
  color: #212529;
  background-color: rgba(0, 0, 0, 0.075);
}

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #b8daff;
}

.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody + tbody {
  border-color: #7abaff;
}

.table-hover .table-primary:hover {
  background-color: #9fcdff;
}

.table-hover .table-primary:hover > td,
.table-hover .table-primary:hover > th {
  background-color: #9fcdff;
}

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #d6d8db;
}

.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody + tbody {
  border-color: #b3b7bb;
}

.table-hover .table-secondary:hover {
  background-color: #c8cbcf;
}

.table-hover .table-secondary:hover > td,
.table-hover .table-secondary:hover > th {
  background-color: #c8cbcf;
}

.table-success,
.table-success > th,
.table-success > td {
  background-color: #c3e6cb;
}

.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody + tbody {
  border-color: #8fd19e;
}

.table-hover .table-success:hover {
  background-color: #b1dfbb;
}

.table-hover .table-success:hover > td,
.table-hover .table-success:hover > th {
  background-color: #b1dfbb;
}

.table-info,
.table-info > th,
.table-info > td {
  background-color: #bee5eb;
}

.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody + tbody {
  border-color: #86cfda;
}

.table-hover .table-info:hover {
  background-color: #abdde5;
}

.table-hover .table-info:hover > td,
.table-hover .table-info:hover > th {
  background-color: #abdde5;
}

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #ffeeba;
}

.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody + tbody {
  border-color: #ffdf7e;
}

.table-hover .table-warning:hover {
  background-color: #ffe8a1;
}

.table-hover .table-warning:hover > td,
.table-hover .table-warning:hover > th {
  background-color: #ffe8a1;
}

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #f5c6cb;
}

.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody + tbody {
  border-color: #ed969e;
}

.table-hover .table-danger:hover {
  background-color: #f1b0b7;
}

.table-hover .table-danger:hover > td,
.table-hover .table-danger:hover > th {
  background-color: #f1b0b7;
}

.table-light,
.table-light > th,
.table-light > td {
  background-color: #fdfdfe;
}

.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody + tbody {
  border-color: #fbfcfc;
}

.table-hover .table-light:hover {
  background-color: #ececf6;
}

.table-hover .table-light:hover > td,
.table-hover .table-light:hover > th {
  background-color: #ececf6;
}

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #c6c8ca;
}

.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
  border-color: #95999c;
}

.table-hover .table-dark:hover {
  background-color: #b9bbbe;
}

.table-hover .table-dark:hover > td,
.table-hover .table-dark:hover > th {
  background-color: #b9bbbe;
}

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075);
}

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075);
}

.table-hover .table-active:hover > td,
.table-hover .table-active:hover > th {
  background-color: rgba(0, 0, 0, 0.075);
}

.table .thead-dark th {
  color: #fff;
  background-color: #343a40;
  border-color: #454d55;
}

.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #dee2e6;
}

.table-dark {
  color: #fff;
  background-color: #343a40;
}

.table-dark th,
.table-dark td,
.table-dark thead th {
  border-color: #454d55;
}

.table-dark.table-bordered {
  border: 0;
}

.table-dark.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(255, 255, 255, 0.05);
}

.table-dark.table-hover tbody tr:hover {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.075);
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-sm > .table-bordered {
    border: 0;
  }
}

@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-md > .table-bordered {
    border: 0;
  }
}

@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-lg > .table-bordered {
    border: 0;
  }

  .options-bar > .route-glance {
    font-size: 14px;
  }
}

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-xl > .table-bordered {
    border: 0;
  }
}

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

.table-responsive > .table-bordered {
  border: 0;
}

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

.table-responsive > .table-bordered {
  border: 0;
}

th:last-child {
  border-right: none;
}

td:last-child {
  border-right: none;
}

.basic-btn {
    background: #333;
    color: #fff;
    border: none;
    margin: 16px 0px;
    border-radius: 10px;
    padding: 12px;
    font-family: inherit;
    font-weight: 600;
    letter-spacing: 1.25;
    cursor: pointer;
  }
  .outline-btn {
    color: #333;
    border: 1px solid #ccc;
    margin: 16px 0px;
    border-radius: 10px;
    padding: 12px;
    cursor: pointer;
    font-family: inherit;
    font-weight: 600;
    letter-spacing: 1.25;
  }
  
  .yellow-btn {
    background: #e6aa33;
    color: #fff;
    border: none;
    margin: 16px 0px;
    border-radius: 10px;
    padding: 12px;
    cursor: pointer;
  }
  
  .red-btn {
    background: #ff2626;
    color: #fff;
    border: none;
    margin: 16px 0px;
    border-radius: 10px;
    padding: 12px;
    cursor: pointer;
  }

  .info-block > .sub-headline,
.info-block > p:first-child {
  margin: 16px 0;
}

.header-options{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.pagination{
  background: #fafafa;
  position: fixed;
  bottom: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  left: 0%;
}


.header{
  font-family: sans-serif,'Open Sans', 'Helvetica Neue';
  font-weight: 400;
  font-size: 1.4em;
  color: #444444;
}

.options-bar{
  display: flex;
  margin: 16px 0;
 
  background: #f5f5f5;
  border-top: 1px solid #ccc ;
  border-bottom: 0.11px solid #ccc ;
  width: 100%;
}

.options-bar > button{
  outline: none;
  background: none;
  border: none;
  padding:16px;
  border-bottom: 2px solid #f5f5f5;
  cursor: pointer;
  font-weight: bold;
  color: #8c8c8c;
}

.options-bar > button:hover{
  border-bottom: 2px solid #333;
  color: #333;
}
.options-bar > button.delete:hover{
  border-bottom: 2px solid #ff1a3a;
  color: #ff1a3a;
}

td.table-view{
  margin: 0;
  padding: 0;
}

.table-view > .options-bar {
  justify-content: space-between;
}

